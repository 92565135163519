import React, { useState, useEffect } from "react";
import "./user.scss";
import UserHeader from "../../components/header/UserHeader";
import { useTranslation } from "react-i18next";
import { useGetAllEcology } from "../../hooks/useGetAllEcology";
import "devextreme/dist/css/dx.light.css";
import { Outlet } from "react-router-dom";
import { useData } from "../../hooks/useData";
import { useGetTemplate } from "../../hooks/useGetTemplate";

export default function UserPage() {
  const { t, i18n } = useTranslation();

  const [responseEco, setResponseEco] = useState({
    isAreas: null,
    areasList: [],
    areas: {},
  });

  const [responseData, setResponseData] = useState({
    mappedData: [],
    gameTypes: [],
    pokerNetworks: [],
    logins: [],
    loginsData: {},
    clubs: [],
    leagues: [],
    players: [],
  });
  const [templatesValues, setTemplatesValues] = useState([]);

  const {
    isPending: isEcologyPending,
    isSuccess: isEcologySuccess,
    data: ecologyData,
  } = useGetAllEcology();
  const {
    isPending: isDataPending,
    isSuccess: isDataSuccess,
    data: dataResult,
  } = useData();

  const mutationGetList = useGetTemplate();

  useEffect(() => {
    if (isDataSuccess) {
      setResponseData(dataResult);
    }
  }, [isDataSuccess, dataResult]);

  useEffect(() => {
    if (isEcologySuccess) {
      setResponseEco(ecologyData);
    }
  }, [isEcologySuccess, ecologyData]);

  useEffect(() => {
    if (isDataSuccess) {
      mutationGetList.mutate({ key: "customUserTemplateName" });
    }
  }, [isDataSuccess]);

  useEffect(() => {
    if (mutationGetList.isSuccess) {
      const names = JSON.parse(mutationGetList.data?.data?.data?.value);
      if (names) {
        setTemplatesValues(names);
      }
    }
  }, [mutationGetList.isSuccess]);

  const { isAreas, areasList, areas } = responseEco;

  useEffect(() => {
    document.title = t("documentTitle");
  }, [i18n.language, t]);

  return (
    <>
      <UserHeader isAreas={isAreas} />
      <Outlet
        context={{
          areas,
          isEcologyPending,
          isAreas,
          areasList,
          responseData,
          isDataSuccess,
          isDataPending,
          templatesValues,
          setTemplatesValues,
        }}
      />
    </>
  );
}
