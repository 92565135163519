import React from "react";
import { useOutletContext } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Loading } from "@carbon/react";

const EcologyRoute = ({ children }) => {
  const { isAreas, isEcologyPending } = useOutletContext();

  if (isEcologyPending || isAreas === null) {
    return (
      <div style={{ margin: "100px" }}>
        <Loading withOverlay={false} />
      </div>
    );
  }

  if (!isAreas) {
    return <Navigate to="/" />;
  }

  return children;
};

export default EcologyRoute;
