import { useMutation } from "@tanstack/react-query";
import { createAxiosWithAuth } from "./middleware";

export const useDeleteAreaConfigItem = () => {
  const axios = createAxiosWithAuth();

  return useMutation({
    mutationFn: async (query) => {
      return await axios.post("/ecology/area/delete", query);
    },
  });
};
