import React, { useState, useEffect } from "react";
import "./limits.scss";
import { Button, ButtonSkeleton, SkeletonPlaceholder } from "@carbon/react";
import { Add } from "@carbon/icons-react";
import LimitItem from "./LimitItem";
import { generateUID } from "../../../utils/generateUID";
import { useTranslation } from "react-i18next";

export default function Limits({
  areasData,
  setAreasData,
  isPending,
  currentArea,
  areasList,
}) {
  const [currentAreaLimits, setCurrentAreaLimits] = useState([]);
  const [currentAreaBBCentsList, setCurrentAreaBBCentsList] = useState([]);
  const [changingLimits, setChangingLimits] = useState({});
  const [changeAll, setChangeAll] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (!isPending && areasData && Object.keys(areasData).length > 0) {
      const areaConfigs = areasData[currentArea].configs;
      setCurrentAreaLimits(areaConfigs);
      setCurrentAreaBBCentsList(areaConfigs.map((item) => item.bbCents));
    }
  }, [currentArea, areasData, isPending]);

  const handleAddLimit = () => {
    const { teamplayEditable, flowEditable } = areasData[currentArea];

    const newObject = {
      gameType: "NL",
      bbCents: "",
      power: 0,
      newLimit: true,
      id: generateUID(),
    };

    if (teamplayEditable) {
      newObject.teamplay = false;
    } else {
      newObject.teamplay = null;
    }

    if (flowEditable) {
      newObject.flow = false;
    } else {
      newObject.flow = null;
    }

    const updatedArray = [...areasData[currentArea].configs, newObject];
    setAreasData({
      ...areasData,
      [currentArea]: { ...areasData[currentArea], configs: updatedArray },
    });
  };

  const handleConfirmAllChanges = () => {
    setChangeAll("confirm");
  };

  const handleCancelAllChanges = () => {
    setChangeAll("cancel");
  };

  useEffect(() => {
    const changingLimitsObject = areasList.reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {});

    setChangingLimits(changingLimitsObject);
  }, []);

  return (
    <div className="limits">
      <div className="limits_title">
        <h4>{t("eco.limits.limitsTitle")}</h4>
        {changingLimits[currentArea]?.length ? (
          <div className="limits_title_buttons">
            <Button kind="tertiary" size="sm" onClick={handleConfirmAllChanges}>
              {t("eco.limits.limitsButtonSaveAll")}
            </Button>
            <Button kind="ghost" size="sm" onClick={handleCancelAllChanges}>
              {t("eco.limits.limitsButtonCancelAll")}
            </Button>
          </div>
        ) : null}
      </div>
      {!isPending ? (
        currentAreaLimits.map((obj) => (
          <LimitItem
            key={`${currentArea}-${obj.id}`}
            config={obj}
            currentArea={currentArea}
            areasData={areasData}
            setAreasData={setAreasData}
            currentAreaLimits={currentAreaLimits}
            changingLimits={changingLimits}
            setChangingLimits={setChangingLimits}
            changeAll={changeAll}
            setChangeAll={setChangeAll}
          />
        ))
      ) : (
        <SkeletonPlaceholder className="skeleton_placeholder" />
      )}
      {!isPending ? (
        <Button
          className="limits_add"
          renderIcon={Add}
          kind="ghost"
          onClick={handleAddLimit}
          disabled={
            currentAreaBBCentsList.some((item) => item === "") ? true : false
          }
        >
          {t("eco.limits.limitsButtonAddLimit")}
        </Button>
      ) : (
        <ButtonSkeleton />
      )}
    </div>
  );
}
