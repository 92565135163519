import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AreaID from "../../../components/ecoUser/areaID/AreaID";
import Limits from "../../../components/ecoUser/limits/Limits";

export default function Ecology() {
  const [currentArea, setCurrentArea] = useState("");
  const [areasData, setAreasData] = useState({});

  const { areas, isEcologyPending, areasList } = useOutletContext();

  useEffect(() => {
    if (!isEcologyPending) {
      setAreasData(areas);
      setCurrentArea(Object.keys(areas)[0]);
    }
  }, [areas, isEcologyPending]);

  return (
    <div className="eco">
      <AreaID
        areasList={areasList}
        isPending={isEcologyPending}
        setCurrentArea={setCurrentArea}
      />
      <Limits
        areasData={areasData}
        setAreasData={setAreasData}
        isPending={isEcologyPending}
        currentArea={currentArea}
        areasList={areasList}
      />
    </div>
  );
}
