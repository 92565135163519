import React, { useState, useEffect } from "react";
import "./limits.scss";
import {
  Checkbox,
  Button,
  TextInput,
  Slider,
  IconButton,
  Toggle,
} from "@carbon/react";
import { TrashCan, Checkmark, Close } from "@carbon/icons-react";
import { useSetAreaConfigItem } from "../../../hooks/useSetAreaConfigItem";
import { useDeleteAreaConfigItem } from "../../../hooks/useDeleteAreaConfigItem";
import { useTranslation } from "react-i18next";

export default function LimitItem({
  config,
  currentArea,
  areasData,
  setAreasData,
  currentAreaLimits,
  changingLimits,
  setChangingLimits,
  changeAll,
  setChangeAll,
}) {
  const { t } = useTranslation();

  const [toBeDeleted, setToBeDeleted] = useState(false);
  const [limitConfig, setLimitConfig] = useState({
    gameTypeValue: config.gameType,
    limitValue: config.bbCents,
    powerValue: config.power,
    teamplayValue: config.teamplay,
    flowValue: config.flow,
    id: config.id,
  });

  const [validation, setValidation] = useState({
    invalid: false,
    invalidText: "",
  });

  const mutationSet = useSetAreaConfigItem();
  const mutationDelete = useDeleteAreaConfigItem();

  const updateNewLimit = (key, value) => {
    setLimitConfig((prev) => ({ ...prev, [key]: value }));
    if (!config.hasOwnProperty("newLimit")) {
      setChangingLimits((prevState) => {
        if (!prevState[currentArea].includes(limitConfig.id)) {
          return {
            ...prevState,
            [currentArea]: [...prevState[currentArea], limitConfig.id],
          };
        }
        return prevState;
      });
    }
  };

  useEffect(() => {
    const isDuplicate = currentAreaLimits.some(
      (obj) =>
        obj.id !== limitConfig.id && obj.bbCents === +limitConfig.limitValue
    );
    if (limitConfig.limitValue === "") {
      setValidation({
        invalid: true,
        invalidText: "eco.limitItem.validation.noLimit",
      });
    } else if (isDuplicate) {
      setValidation({
        invalid: true,
        invalidText: "eco.limitItem.validation.existLimit",
      });
    } else {
      setValidation({ invalid: false, invalidText: "" });
    }
  }, [limitConfig.limitValue, setValidation]);

  const handleDelete = () => {
    const updatedConfigs = areasData[currentArea].configs.filter(
      (obj) => obj.bbCents !== limitConfig.limitValue
    );
    setAreasData({
      ...areasData,
      [currentArea]: { configs: updatedConfigs },
    });

    mutationDelete.mutate({
      areaId: currentArea,
      gameType: limitConfig.gameTypeValue,
      bbCents: limitConfig.limitValue,
    });
  };

  const handleCreateLimit = () => {
    if (config.hasOwnProperty("newLimit")) {
      const updatedConfigs = areasData[currentArea].configs.map((obj) =>
        obj.id === limitConfig.id
          ? {
              gameType: limitConfig.gameTypeValue,
              bbCents: limitConfig.limitValue,
              power: limitConfig.powerValue,
              teamplay: limitConfig.teamplayValue,
              flow: limitConfig.flowValue,
              id: limitConfig.id,
            }
          : obj
      );
      setAreasData({
        ...areasData,
        [currentArea]: { configs: updatedConfigs },
      });
      mutationSet.mutate({
        areaId: currentArea,
        gameType: limitConfig.gameTypeValue,
        bbCents: limitConfig.limitValue,
        power: limitConfig.powerValue,
        teamplay: limitConfig.teamplayValue,
        flow: limitConfig.flowValue,
      });
    }
  };

  const handleCancelCreationLimit = () => {
    const updatedConfigs = areasData[currentArea].configs.filter(
      (obj) => obj.id !== limitConfig.id
    );
    setAreasData({
      ...areasData,
      [currentArea]: { configs: updatedConfigs },
    });
  };

  const handleEditLimit = () => {
    if (changingLimits[currentArea].some((item) => item === limitConfig.id)) {
      const updatedConfigs = areasData[currentArea].configs.map((obj) =>
        obj.id === limitConfig.id
          ? {
              gameType: limitConfig.gameTypeValue,
              bbCents: limitConfig.limitValue,
              power: limitConfig.powerValue,
              teamplay: limitConfig.teamplayValue,
              flow: limitConfig.flowValue,
              id: limitConfig.id,
            }
          : obj
      );
      setAreasData({
        ...areasData,
        [currentArea]: { configs: updatedConfigs },
      });
      setChangingLimits((prevState) => {
        const { [currentArea]: keyArray, ...restState } = prevState;

        return {
          ...restState,
          [currentArea]: keyArray.filter((item) => item !== limitConfig.id),
        };
      });
      if (config.bbCents !== limitConfig.limitValue) {
        mutationDelete.mutate({
          areaId: currentArea,
          gameType: config.gameType,
          bbCents: config.bbCents,
        });
        mutationSet.mutate({
          areaId: currentArea,
          gameType: limitConfig.gameTypeValue,
          bbCents: limitConfig.limitValue,
          power: limitConfig.powerValue,
          teamplay: limitConfig.teamplayValue,
          flow: limitConfig.flowValue,
        });
      } else {
        mutationSet.mutate({
          areaId: currentArea,
          gameType: limitConfig.gameTypeValue,
          bbCents: limitConfig.limitValue,
          power: limitConfig.powerValue,
          teamplay: limitConfig.teamplayValue,
          flow: limitConfig.flowValue,
        });
      }
    }
  };

  const handleCancelEditLimit = () => {
    setChangingLimits((prevState) => {
      const { [currentArea]: keyArray, ...restState } = prevState;

      return {
        ...restState,
        [currentArea]: keyArray.filter((item) => item !== limitConfig.id),
      };
    });
    setLimitConfig({
      limitValue: config.bbCents,
      powerValue: config.power,
      teamplayValue: config.teamplay,
      flowValue: config.flow,
      id: config.id,
    });
  };

  useEffect(() => {
    if (changeAll === "confirm") {
      handleEditLimit();
      setChangeAll("");
    } else if (changeAll === "cancel") {
      handleCancelEditLimit();
      setChangeAll("");
    } else return;
  }, [changeAll]);

  return (
    <div
      className={`limits_limit ${
        config.hasOwnProperty("newLimit")
          ? "new-limit"
          : changingLimits[currentArea].some((item) => item === limitConfig.id)
          ? "edited-limit"
          : ""
      }`}
    >
      <div className="limits_limit_select">
        <div className="limits_limit_select_text">
          <h4>{limitConfig.gameTypeValue}</h4>
          <TextInput
            id={`text-input-${currentArea}-${config.id}`}
            labelText=""
            type="text"
            size="sm"
            className="limits_limit_select_text_input"
            hideLabel={true}
            value={limitConfig.limitValue}
            onChange={(e) =>
              updateNewLimit("limitValue", Number(e.target.value))
            }
            invalid={validation.invalid}
            invalidText={
              validation.invalidText ? t(validation.invalidText) : ""
            }
          />
        </div>
        <div className="limits_limit_select_buttons">
          {/* <Checkbox
            labelText={`Show in graph`}
            id={`checkbox-label-${config.id}`}
          /> */}
          {!config.hasOwnProperty("newLimit") &&
          !changingLimits[currentArea].some(
            (item) => item === limitConfig.id
          ) ? (
            !toBeDeleted ? (
              <IconButton
                kind="ghost"
                label={t("eco.limitItem.buttons.delete")}
                size="sm"
                autoAlign
                onClick={() => setToBeDeleted(true)}
              >
                <TrashCan
                  className="limits_limit_select_buttons_delete"
                  size={20}
                />
              </IconButton>
            ) : (
              <div className="limits_limit_select_buttons_confirm_delete">
                <IconButton
                  kind="ghost"
                  label={t("eco.limitItem.buttons.confirm")}
                  size="sm"
                  autoAlign
                  onClick={handleDelete}
                >
                  <Checkmark size={20} />
                </IconButton>
                <IconButton
                  kind="ghost"
                  label={t("eco.limitItem.buttons.cancel")}
                  size="sm"
                  autoAlign
                  onClick={() => setToBeDeleted(false)}
                >
                  <Close size={20} />
                </IconButton>
              </div>
            )
          ) : null}
        </div>
      </div>
      <Slider
        id={`slider-${currentArea}-${config.id}`}
        labelText=""
        value={8 - limitConfig.powerValue * 10}
        min={0}
        max={8}
        step={1}
        noValidate
        hideTextInput={true}
        onRelease={(e) => updateNewLimit("powerValue", (8 - e.value) / 10)}
        formatLabel={(val) => {
          if (val < 1) {
            return `${t("eco.limitItem.power.weak")}`;
          } else if (val > 7) {
            return `${t("eco.limitItem.power.strong")}`;
          }
          return val;
        }}
      />
      <div className="limits_limit_toggle">
        {limitConfig.teamplayValue !== null && (
          <Toggle
            labelText={t("eco.limitItem.toggle.teamplay")}
            hideLabel
            toggled={limitConfig.teamplayValue}
            id={`toggle-teamplay-${currentArea}-${config.id}`}
            onToggle={() =>
              updateNewLimit("teamplayValue", !limitConfig.teamplayValue)
            }
          />
        )}
        {limitConfig.flowValue !== null && (
          <Toggle
            labelText={t("eco.limitItem.toggle.giveInToFish")}
            hideLabel
            toggled={limitConfig.flowValue}
            id={`toggle-flow-${currentArea}-${config.id}`}
            onToggle={() => updateNewLimit("flowValue", !limitConfig.flowValue)}
          />
        )}
      </div>
      {config.hasOwnProperty("newLimit") && (
        <div className="limits_limit_edit">
          {" "}
          <Button
            size="sm"
            kind="primary"
            onClick={handleCreateLimit}
            disabled={!limitConfig.limitValue || validation.invalid}
          >
            {t("eco.limitItem.buttons.create")}
          </Button>
          <Button kind="ghost" size="sm" onClick={handleCancelCreationLimit}>
            {t("eco.limitItem.buttons.cancel")}
          </Button>
        </div>
      )}
      {changingLimits[currentArea].some((item) => item === limitConfig.id) &&
        !config.hasOwnProperty("newLimit") && (
          <div className="limits_limit_edit">
            {" "}
            <Button
              size="sm"
              kind="primary"
              onClick={handleEditLimit}
              disabled={!limitConfig.limitValue || validation.invalid}
            >
              {t("eco.limitItem.buttons.confirm")}
            </Button>
            <Button kind="ghost" size="sm" onClick={handleCancelEditLimit}>
              {t("eco.limitItem.buttons.cancel")}
            </Button>
          </div>
        )}
    </div>
  );
}
