import React, { useState } from "react";
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SkeletonText,
} from "@carbon/react";
import "./areaId.scss";

export default function AreaID({ areasList, isPending, setCurrentArea }) {
  const [sideNav, setSideNav] = useState(0);
  return (
    <>
      <SideNav isFixedNav expanded={true} aria-label="Side navigation">
        <SideNavItems>
          {!isPending ? (
            areasList?.map((str, index) => (
              <SideNavLink
                key={index}
                as="div"
                className={`${sideNav === index ? "sideNav-active" : ""}`}
                onClick={() => {
                  setSideNav(index);
                  setCurrentArea(str);
                }}
              >
                {str}
              </SideNavLink>
            ))
          ) : (
            <SkeletonText />
          )}
        </SideNavItems>
      </SideNav>
    </>
  );
}
