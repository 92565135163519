import React, { useState, useMemo, useRef } from "react";
import Toolbar from "../../../components/statsUser/toolbar/Toolbar";
import IncomeChart from "../../../components/statsUser/chart/IncomeChart";
import DataTable from "../../../components/statsUser/dataTable/DataTable";
import "../user.scss";
import { filteredDataMapper } from "../../../mappers/filteredDataMapper";
import "devextreme/dist/css/dx.light.css";
import { useOutletContext } from "react-router-dom";

export default function Statistics() {
  const dataGridRef = useRef(null);

  const [filterOptions, setFilterOptions] = useState({
    dateFromTo: [],
    selectedPokerNetworks: [],
    selectedGameTypes: [],
    selectedLogins: [],
    selectedPIDs: [],
    selectedClubs: [],
    selectedLeagues: [],
  });

  const { responseData, isDataPending, isDataSuccess, templatesValues, setTemplatesValues } = useOutletContext();

  const {
    mappedData,
    gameTypes,
    pokerNetworks,
    logins,
    loginsData,
    clubs,
    leagues,
    players,
  } = responseData;

  const filteredData = useMemo(
    () => filteredDataMapper(mappedData, filterOptions),
    [mappedData, filterOptions]
  );

  return (
    <div className="stats">
      <Toolbar
        setFilterOptions={setFilterOptions}
        gameTypes={gameTypes}
        pokerNetworks={pokerNetworks}
        logins={logins}
        loginsData={loginsData}
        clubs={clubs}
        leagues={leagues}
        players={players}
        dataGridRef={dataGridRef}
        isPending={isDataPending}
      />
      <IncomeChart filteredData={filteredData} isPending={isDataPending} />
      <DataTable
        filteredData={filteredData}
        isSuccess={isDataSuccess}
        ref={dataGridRef}
        templatesValues={templatesValues}
        setTemplatesValues={setTemplatesValues}
      />
    </div>
  );
}
