import { useCallback } from "react";
import { useAuthQuery } from "./useAuthQuery";
import { generateUID } from "../utils/generateUID";

export const useGetAllEcology = () => {
  const selectFn = useCallback((data) => {
    const { areas } = data?.data?.data;

    const areasWithUID = Object.keys(areas).reduce((acc, key) => {
      const area = areas[key];

      const updatedConfigs = area.configs.map((config) => ({
        ...config,
        id: generateUID(),
      }));

      acc[key] = {
        ...area,
        configs: updatedConfigs,
      };

      return acc;
    }, {});

    return {
      isAreas: !!Object.keys(areas).length,
      areasList: Object.keys(areas),
      areas: areasWithUID,
    };
  }, []);

  const response = useAuthQuery(
    ["getEco"],
    {
      method: "POST",
      url: "/ecology/area/all",
    },
    selectFn
  );

  return response;
};
