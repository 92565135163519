import React, { useState, useEffect } from "react";
import {
  Header,
  SkipToContent,
  HeaderName,
  HeaderMenu,
  HeaderMenuItem,
  HeaderNavigation,
  HeaderGlobalBar,
  HeaderGlobalAction,
} from "@carbon/react";
import "./header.scss";
import { Logout } from "@carbon/icons-react";
import logoNZT from "../../assets/nzt/mainLogo.svg";
import logoJIN from "../../assets/jin/mainLogo.svg";
import { useTranslation } from "react-i18next";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { queryClient } from "../..";

const languages = [
  { id: "en", lang: "ENG" },
  { id: "zh", lang: "中文" },
  { id: "pt", lang: "POR" },
  { id: "ru", lang: "РУС" },
];

export default function UserHeader({ isAreas }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const version = process.env.REACT_APP_VERSION;

  const [currentLanguage, setCurrentLanguage] = useState("");

  const [activeNavItem, setActiveNavItem] = useState("/");

  useEffect(() => {
    setActiveNavItem(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    switch (i18n.language.slice(0, 2)) {
      case "en":
        setCurrentLanguage("ENG");
        break;
      case "zh":
        setCurrentLanguage("中文");
        break;
      case "pt":
        setCurrentLanguage("POR");
        break;
      case "ru":
        setCurrentLanguage("РУС");
        break;
      default:
        break;
    }
  }, [i18n.language]);

  const redirectToLogin = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userLogin");
    queryClient.clear();

    navigate("/login");
  };

  return (
    <Header aria-label="NZT">
      <SkipToContent />
      <HeaderName prefix="">
        <img src={version === "NZT" ? logoNZT : logoJIN} alt="logo" />
      </HeaderName>
      <HeaderNavigation aria-label="NZT">
        <Link
          to={`/`}
          className={`navLink ${
            activeNavItem === "/" ? "header__menu-item-active" : ""
          }`}
          onClick={() => setActiveNavItem("/")}
        >
          {t("main.header.stats")}
        </Link>
        {isAreas ? (
          <Link
            to={`/eco`}
            className={`navLink ${
              activeNavItem === "/eco" ? "header__menu-item-active" : ""
            }`}
            onClick={() => setActiveNavItem("/eco")}
          >
            {t("main.header.ecology")}
          </Link>
        ) : (
          <></>
        )}
      </HeaderNavigation>
      <HeaderGlobalBar>
        <HeaderMenu
          aria-label=""
          menuLinkName={currentLanguage}
          align="bottom-right"
        >
          {languages.map((item) => (
            <HeaderMenuItem
              onClick={() => i18n.changeLanguage(item.id)}
              key={item.id}
            >
              {item.lang}
            </HeaderMenuItem>
          ))}
        </HeaderMenu>
        <HeaderGlobalAction
          aria-label={t("main.header.exit")}
          tooltipAlignment="center"
          onClick={() => redirectToLogin()}
        >
          <Logout size={20} />
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </Header>
  );
}
