import React, {
  useMemo,
  forwardRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import "./dataTable.scss";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Summary,
  TotalItem,
  GroupItem,
  Paging,
  ColumnChooser,
  ColumnChooserSelection,
  Position,
  Toolbar,
  Item,
  StateStoring,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import { Loading } from "@carbon/react";
import { useTranslation } from "react-i18next";
import { tableDataMapper } from "../../../mappers/tableDataMapper";
import CreateTemplateModal from "../../templateModal/CreateTemplateModal";
import { useGetTemplate } from "../../../hooks/useGetTemplate";
import { useSetTemplate } from "../../../hooks/useSetTemplate";
import { columnsConfig } from "../../../consts/columnsConfig";
import { formatNumberWithSpaces } from "../../../utils/formatNumberWithSpaces";
import { calculateWeightedAverage } from "../../../utils/calculateWeightedAverage";
import { cellColorPrepared } from "../../../utils/cellColorPrepared";

import config from "devextreme/core/config";

import ruMessages from "devextreme/localization/messages/ru.json";
import enMessages from "devextreme/localization/messages/en.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import zhMessages from "devextreme/localization/messages/zh.json";
import { locale, loadMessages, formatMessage } from "devextreme/localization";

import save from "../../../assets/icons/save.svg";
import refresh from "../../../assets/icons/refresh.svg";
import settings from "../../../assets/icons/settings.svg";
import information from "../../../assets/icons/information.svg";
import InformationModal from "../../infoModal/InformationModal";
import { presetTemplates } from "../../../consts/templates";

loadMessages(ruMessages);
loadMessages(enMessages);
loadMessages(ptMessages);
loadMessages(zhMessages);

const devextremeKey = process.env.REACT_APP_DEVEXTREME_LICENSE;

config({ devextremeKey });

const DataTable = forwardRef(function DataTable(
  { filteredData, isSuccess, templatesValues, setTemplatesValues },
  ref
) {
  const { t, i18n } = useTranslation();

  locale(i18n.language);

  const presetTemplatesNames = presetTemplates.map((item) => t(item.name));

  const [createModalVisibility, setCreateModalVisibility] = useState(false);
  const [informationModalVisibility, setInformationModalVisibility] =
    useState(false);
  const [allTemplatesValues, setAllTemplatesValues] = useState([]);
  const [selectedTemplateValue, setSelectedTemplateValue] = useState(null);

  const mutationSetItem = useSetTemplate();
  const mutationGetItem = useGetTemplate();

  const tableData = useMemo(
    () => tableDataMapper(filteredData),
    [filteredData]
  );

  const onStateResetClick = useCallback(() => {
    ref.current.instance.state(null);
    setSelectedTemplateValue(null);
  }, []);

  const handleLayoutSelect = (e) => {
    setSelectedTemplateValue(e.value);
  };

  const handleChangeCurrentTemplateValue = () => {
    mutationSetItem.mutate({
      key: selectedTemplateValue,
      value: localStorage.getItem("storage"),
    });
  };

  useEffect(() => {
    if (selectedTemplateValue) {
      if (presetTemplatesNames.includes(selectedTemplateValue)) {
        const item = presetTemplates.find(
          (item) => t(item.name) === selectedTemplateValue
        );
        ref.current.instance.state(item.value);
      } else {
        mutationGetItem.mutate({ key: selectedTemplateValue });
      }
    }
  }, [selectedTemplateValue]);

  useEffect(() => {
    if (mutationGetItem.isSuccess) {
      ref.current.instance.state(
        JSON.parse(mutationGetItem.data?.data?.data?.value)
      );
    }
  }, [mutationGetItem.isSuccess]);

  useEffect(() => {
    if (templatesValues) {
      setAllTemplatesValues([...presetTemplatesNames, ...templatesValues]);
    }
  }, [templatesValues, i18n.language]);

  if (!isSuccess) {
    return (
      <div className="data_table_loading">
        <Loading withOverlay={false} />
      </div>
    );
  }

  return (
    <div className="data_table dx-viewport">
      <DataGrid
        // key={i18n.language}
        id="gridContainer"
        dataSource={tableData}
        keyExpr="ID"
        allowColumnReordering={true}
        allowColumnResizing={true}
        width="100%"
        showBorders={true}
        showRowLines={true}
        grouping={{
          texts: {
            groupContinuesMessage: "",
            groupContinuedMessage: "",
          },
        }}
        loadPanel={{
          text: formatMessage("Loading"),
        }}
        noDataText={formatMessage("dxNumberBox-noDataText")}
        onCellPrepared={cellColorPrepared}
        ref={ref}
        emptyPanelText={formatMessage(
          "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText"
        )}
      >
        <ColumnChooser
          enabled={true}
          mode="select"
          title={t("main.dataTable.columnChooserTitle")}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
          <ColumnChooserSelection allowSelectAll={true} selectByClick={true} />
        </ColumnChooser>
        <StateStoring enabled={true} type="localStorage" storageKey="storage" />
        <GroupPanel
          visible={true}
          emptyPanelText={formatMessage("dxDataGrid-groupPanelEmptyText")}
        />
        <Grouping width={500} />
        <Paging defaultPageSize={50} />

        <Summary
          texts={"Sum={0}"}
          calculateCustomSummary={(options) => {
            if (options.name === "ErrorWAvg") {
              calculateWeightedAverage(
                options,
                options.value?.Error.percent,
                options.value?.Error.hands
              );
            }
            if (options.name === "HUWAvg") {
              calculateWeightedAverage(
                options,
                options.value?.HUPercent,
                options.value?.Hands
              );
            }
            if (options.name === "BBWAvg") {
              calculateWeightedAverage(
                options,
                options.value?.NetWinrate,
                options.value?.Hands
              );
            }
            if (options.name === "EVBBWAvg") {
              calculateWeightedAverage(
                options,
                options.value?.AllinEVWinrate,
                options.value?.Hands
              );
            }
            if (options.name === "RBTablesWAvg") {
              calculateWeightedAverage(
                options,
                options.value?.redBlackTablesPercent,
                options.value?.Hands
              );
            }
            if (options.name === "RBTables") {
              switch (options.summaryProcess) {
                case "start":
                  options.redSum = 0;
                  options.blackSum = 0;
                  break;
                case "calculate":
                  options.redSum += Number(
                    options.value.redBlackTables.split(" / ")[0]
                  );
                  options.blackSum += Number(
                    options.value.redBlackTables.split(" / ")[1]
                  );

                  break;
                case "finalize":
                  options.totalValue = `${options.redSum} / ${options.blackSum}`;

                  break;
                default:
                  break;
              }
            }
          }}
        >
          <TotalItem
            column="Hands"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) => formatNumberWithSpaces(cellInfo)}
          />
          <TotalItem
            column="NetWinUsd"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <TotalItem
            column="AllinEVUsd"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <TotalItem
            column="NetWin"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <TotalItem
            column="AllinEV"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <TotalItem
            column="Fuel"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <TotalItem
            showInColumn="NetWinrate"
            name="BBWAvg"
            summaryType="custom"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2))
            }
          />
          <TotalItem
            showInColumn="AllinEVWinrate"
            name="EVBBWAvg"
            summaryType="custom"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2))
            }
          />
          <TotalItem
            column="HandsHU"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) => formatNumberWithSpaces(cellInfo)}
          />
          <TotalItem
            showInColumn="HUPercent"
            name="HUWAvg"
            summaryType="custom"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo) + " %"
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2)) + " %"
            }
          />
          <TotalItem
            column="HandsOperatorErrors"
            summaryType="sum"
            alignment="left"
            displayFormat={(cellInfo) => formatNumberWithSpaces(cellInfo)}
          />
          <TotalItem
            showInColumn="Error.percent"
            name="ErrorWAvg"
            summaryType="custom"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo) + " %"
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2)) + " %"
            }
          />
          <TotalItem
            showInColumn="redBlackTables"
            name="RBTables"
            summaryType="custom"
            alignment="left"
          />
          <TotalItem
            showInColumn="redBlackTablesPercent"
            name="RBTablesWAvg"
            summaryType="custom"
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo) + " %"
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2)) + " %"
            }
          />
          <GroupItem
            column="Hands"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) => formatNumberWithSpaces(cellInfo)}
          />
          <GroupItem
            column="NetWinUsd"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <GroupItem
            column="AllinEVUsd"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <GroupItem
            column="NetWin"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <GroupItem
            column="AllinEV"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <GroupItem
            column="Fuel"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(1))
            }
          />
          <GroupItem
            showInColumn="NetWinrate"
            name="BBWAvg"
            summaryType="custom"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2))
            }
          />
          <GroupItem
            showInColumn="AllinEVWinrate"
            name="EVBBWAvg"
            summaryType="custom"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo)
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2))
            }
          />
          <GroupItem
            column="HandsHU"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) => formatNumberWithSpaces(cellInfo)}
          />
          <GroupItem
            showInColumn="HUPercent"
            name="HUWAvg"
            summaryType="custom"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) =>
              cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo) + " %"
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2)) + " %"
            }
          />
          <GroupItem
            column="HandsOperatorErrors"
            summaryType="sum"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) => formatNumberWithSpaces(cellInfo)}
          />
          <GroupItem
            showInColumn="Error.percent"
            name="ErrorWAvg"
            summaryType="custom"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) => {
              return cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo) + " %"
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2)) + " %";
            }}
          />
          <GroupItem
            showInColumn="redBlackTables"
            summaryType="custom"
            name="RBTables"
            alignByColumn
            alignment="left"
          />
          <GroupItem
            showInColumn="redBlackTablesPercent"
            name="RBTablesWAvg"
            summaryType="custom"
            alignByColumn
            alignment="left"
            displayFormat={(cellInfo) => {
              return cellInfo % 1 === 0
                ? formatNumberWithSpaces(cellInfo) + " %"
                : formatNumberWithSpaces(Number(cellInfo).toFixed(2)) + " %";
            }}
          />
        </Summary>

        {columnsConfig.map((column, index) => (
          <Column
            key={index}
            dataField={column.dataField}
            dataType={column.dataType}
            caption={column.translate ? t(column.caption) : column.caption}
            name={column.name}
            groupIndex={column.groupIndex}
            autoExpandGroup={column.autoExpandGroup}
            visible={column.visible}
            width={column.width}
            customizeText={column.customizeText}
          />
        ))}

        <Toolbar>
          <Item name="groupPanel" location="before" />
          <Item location="after">
            <Button
              icon={save}
              hint={t("main.dataTable.toolbarHints.saveValue")}
              visible={
                selectedTemplateValue !== null &&
                presetTemplatesNames.indexOf(selectedTemplateValue) > 4
              }
              onClick={handleChangeCurrentTemplateValue}
            />
          </Item>
          <Item location="after">
            <Button
              icon={settings}
              hint={t("main.dataTable.toolbarHints.settings")}
              onClick={() => setCreateModalVisibility(true)}
            />
          </Item>
          <Item location="after">
            <SelectBox
              dropDownOptions={{ width: 150 }}
              width="150"
              inputAttr={{ "aria-label": "Templates" }}
              placeholder={t(
                "main.dataTable.toolbarHints.templatesPlaceholder"
              )}
              items={allTemplatesValues}
              value={selectedTemplateValue}
              onValueChanged={handleLayoutSelect}
            />
          </Item>
          <Item location="after">
            <Button
              icon={refresh}
              onClick={onStateResetClick}
              hint={t("main.dataTable.toolbarHints.refresh")}
            />
          </Item>
          <Item name="columnChooserButton" location="after" />
          <Item location="after">
            <Button
              icon={information}
              hint={t("main.dataTable.toolbarHints.information")}
              onClick={() => setInformationModalVisibility(true)}
            />
          </Item>
        </Toolbar>
      </DataGrid>

      <CreateTemplateModal
        createModalVisibility={createModalVisibility}
        setCreateModalVisibility={setCreateModalVisibility}
        templatesValues={templatesValues}
        setTemplatesValues={setTemplatesValues}
      />
      <InformationModal
        informationModalVisibility={informationModalVisibility}
        setInformationModalVisibility={setInformationModalVisibility}
      />
    </div>
  );
});

export default DataTable;
